import {makeAutoObservable} from "mobx";
import {api, catchApi, SwitchTenantEnum} from "../api";
import {authStore, getJwt, JWT} from "./AuthStore";

const tenants: string[] = []
for(let n in SwitchTenantEnum) {
    tenants.push(n)
}



function isSuperAdmin(jwt: JWT) {

    const index = jwt.realm_access.roles.indexOf("superadmin")
    return index !== -1
}

class TenantSelectStore {
    get tenant(): string | undefined {
        return this._tenant;
    }

    set tenant(value: string | undefined) {
        this._tenant = value;
    }
    get superadmin(): boolean {
        return this._superadmin;
    }

    set superadmin(value: boolean) {
        this._superadmin = value;
    }

    get token(): string | undefined {
        return this._token;
    }

    set token(value: string | undefined) {
        this._token = value;
    }

    constructor() {
        makeAutoObservable(this)
    }

    tenants = tenants

    private _token: string | undefined
    private _superadmin: boolean = false
    private _tenant: string | undefined

    updateToken(token: string) {
        this.token = token
        const jwt = getJwt(token)
        this.tenant = jwt.tenantId
        this.superadmin = isSuperAdmin(jwt)
    }

    async switch(tenant: string) {
        await catchApi(async () => {
            await api.v1.switchTenant(tenant as any)
            await authStore.auth?.removeUser()
            window.location.reload()
        })
    }

    async reset() {
        await catchApi(async () => {
            await api.v1.leaveTenantGroup()
            await authStore.auth?.removeUser()
            window.location.reload()
        })
    }
}

const tenantSelectStore = new TenantSelectStore()

export {
    tenantSelectStore
}
