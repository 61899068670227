import {observer} from "mobx-react";
import React from "react";
import {AddDocumentParamsEnum, RegistrationDtoOrderStatusEnum, RegistrationDtoServiceTypeEnum} from "../../../../../api";
import {DocumentElement} from "./DocumentElement";
import {registrationStore} from "../../../../../store";


const DocumentPanel = observer(function DocumentUploadPanel({
                                                                finishedOnly
                                                            }: {
    finishedOnly?: boolean
}) {
    const disabled = registrationStore.isReadonly
    const displayOnly = registrationStore.status === RegistrationDtoOrderStatusEnum.SUCCESS || registrationStore.status === RegistrationDtoOrderStatusEnum.FINISHED
    const isDeregistration = registrationStore.serviceType === RegistrationDtoServiceTypeEnum.AB
    return <>
        {!displayOnly && finishedOnly ? <></> : <>
            <nav className="panel">
                <p className="panel-heading">Dokumente</p>
                <div className="panel-block">
                    <div>
                        {displayOnly && <>
                            <DocumentElement type={AddDocumentParamsEnum.FEE_RECEIPT} displayOnly={true}/>
                            {!isDeregistration && <DocumentElement type={AddDocumentParamsEnum.PRELIMINARY_REGISTRATION_PROOF}
                                                                   displayOnly={true}
                                                                   sendable={registrationStore.hasEmail}/>}
                            {isDeregistration && <DocumentElement type={AddDocumentParamsEnum.DEREGISTRATION_RECEIPT}
                                                                   displayOnly={true}/>}
                        </>}
                        {!finishedOnly && <>
                            {!registrationStore.isCorporate &&
                                <DocumentElement
                                    type={AddDocumentParamsEnum.DATA_CONSENT}
                                    generateDisabled={!registrationStore.readyToGenerateDPA}
                                    disabled={disabled}
                                    displayOnly={displayOnly}/>}
                            {!registrationStore.selfRegistration &&
                                <DocumentElement
                                    type={AddDocumentParamsEnum.POA}
                                    disabled={disabled}
                                    generateDisabled={!registrationStore.readyToGeneratePOA}
                                    displayOnly={displayOnly}/>
                            }
                            {registrationStore.serviceType !== RegistrationDtoServiceTypeEnum.HA &&
                                <DocumentElement
                                    type={AddDocumentParamsEnum.SEPA}
                                    disabled={disabled}
                                    generateDisabled={!registrationStore.readyToGenerateSEPA}
                                    displayOnly={displayOnly}/>
                            }
                        </>}
                    </div>
                </div>
            </nav>
        </>}
    </>
})

export {
    DocumentPanel
}
