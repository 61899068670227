import {makeAutoObservable} from "mobx";
import {
    api, catchApi,
    OrganizationDto,
    RegistrationListItemDtoOrderStatusEnum,
    RegistrationListItemDtoOrderTypeEnum
} from "../api";
import {SelectOption} from "../components/ui/OrganizationSelect";
import {mapOrganizationDtoToSelectOption} from "./RegistrationStoreUtils";
import {URLSearchParams} from "node:url";


export enum ArchiveStatus {
    HIDE_ARCHIVED = "HIDE_ARCHIVED",
    SHOW_ARCHIVED = "SHOW_ARCHIVED",
    SHOW_ARCHIVED_ONLY = "SHOW_ARCHIVED_ONLY",
}

class RegistrationOverviewFilterStore {
    constructor() {
        makeAutoObservable(this)
    }

    private _selectedServiceTypes: RegistrationListItemDtoOrderTypeEnum[] = []
    private _selectedStatus: RegistrationListItemDtoOrderStatusEnum[] = []
    private _from: string | undefined = undefined
    private _to: string | undefined = undefined
    private _organizationId: string | undefined = undefined
    private _archived: ArchiveStatus = ArchiveStatus.HIDE_ARCHIVED

    private _allOrganizations: OrganizationDto[] = []

    private _visible = false


    get selectedServiceTypes(): RegistrationListItemDtoOrderTypeEnum[] {
        return this._selectedServiceTypes;
    }

    set selectedServiceTypes(value: RegistrationListItemDtoOrderTypeEnum[]) {
        this._selectedServiceTypes = value;
    }

    addSelectedServiceType(type: RegistrationListItemDtoOrderTypeEnum) {
        if (!this._selectedServiceTypes.includes(type)) {
            this._selectedServiceTypes.push(type)
        }
    }

    removeSelectedServiceType(type: RegistrationListItemDtoOrderTypeEnum) {
        const index = this._selectedServiceTypes.indexOf(type)
        if (index > -1) {
            this._selectedServiceTypes.splice(index, 1)
        }
    }

    get selectedStatus(): RegistrationListItemDtoOrderStatusEnum[] {
        return this._selectedStatus;
    }

    set selectedStatus(value: RegistrationListItemDtoOrderStatusEnum[]) {
        this._selectedStatus = value;
    }

    addSelectedStatus(status: RegistrationListItemDtoOrderStatusEnum) {
        if (!this._selectedStatus.includes(status)) {
            this._selectedStatus.push(status)
        }
    }

    removeSelectedStatus(status: RegistrationListItemDtoOrderStatusEnum) {
        const index = this._selectedStatus.indexOf(status)
        if (index > -1) {
            this._selectedStatus.splice(index, 1)
        }
    }

    get from(): string | undefined {
        return this._from;
    }

    set from(value: string | undefined) {
        this._from = value;
    }

    get to(): string | undefined {
        return this._to;
    }

    set to(value: string | undefined) {
        this._to = value;
    }

    get organizationId(): string | undefined {
        return this._organizationId;
    }

    set organizationId(value: string | undefined) {
        this._organizationId = value;
    }

    get archived(): ArchiveStatus {
        return this._archived;
    }

    set archived(value: ArchiveStatus) {
        this._archived = value;
    }

    get allOrganizations(): OrganizationDto[] {
        return this._allOrganizations;
    }

    set allOrganizations(value: OrganizationDto[]) {
        this._allOrganizations = value;
    }

    get organization(): SelectOption<OrganizationDto> | undefined {
        if (this.organizationId && this.allOrganizations) {
            const el = this.allOrganizations.find(e => e.id === this.organizationId)
            return el ? {label: el.name, value: el} : undefined
        }
        return undefined;
    }

    set organization(value: SelectOption<OrganizationDto> | undefined) {
        this.organizationId = value && value.value ? value.value.id : undefined
    }

    get visible(): boolean {
        return this._visible;
    }

    set visible(value: boolean) {
        this._visible = value;
    }

    async searchOrganization(value: string): Promise<SelectOption<OrganizationDto>[]> {
        return await catchApi(async () => {
            const response = await api.v1.listOrganizations()
            const data = response.data
            const v = value.toLowerCase()
            return data.filter(o => {
                return o.id === v || o.costCenter === v || o.name.toLowerCase().split(v).length > 1
            }).map(mapOrganizationDtoToSelectOption)
        }) || []
    }

    async load() {
        await catchApi(async () => {
            const organizationResponse = await api.v1.listOrganizations()
            this.allOrganizations = organizationResponse.data || []
        })
    }

    init(searchParams: URLSearchParams) {
        this.selectedServiceTypes = searchParams.getAll("types") as RegistrationListItemDtoOrderTypeEnum[] || []
        this.selectedStatus = searchParams.getAll("status") as RegistrationListItemDtoOrderStatusEnum[] || []
        this.from = searchParams.get("from") || undefined
        this.to = searchParams.get("to") || undefined
        this.organizationId = searchParams.get("organisationId") || undefined
        const archivedInSearch = searchParams.get("archived");
        this.archived = archivedInSearch !== null ? archivedInSearch as ArchiveStatus : ArchiveStatus.HIDE_ARCHIVED
    }
}


const registrationOverviewFilterStore = new RegistrationOverviewFilterStore()
export {
    registrationOverviewFilterStore
}
