import {observer} from "mobx-react";
import {registrationStore} from "../../../../../../store";
import {RegistrationLicensePlateField} from "../../../fields/RegistrationLicensePlateField";

const PreviousRegistrationPanelUm = observer(function PreviousRegistrationPanel() {

    return <>
        <nav className="panel">
            <p className="panel-heading">Informationen vorheriger Zulassung</p>
            <div className="panel-block">
                <div>
                    <div className={"columns"}>
                        <div className={"column is-half"}>
                            <label className="checkbox" style={{userSelect: 'none'}}>
                                <input
                                    type="checkbox"
                                    checked={registrationStore.isDeregistered}
                                    onBlur={() => registrationStore.validate()}
                                    onChange={e => {
                                        registrationStore.isDeregistered = e.currentTarget.checked
                                    }}
                                />
                                Fahrzeug ist abgemeldet
                            </label>
                        </div>
                    </div>
                    <div className={"columns"}>
                        <div className={"column is-half"} style={{display: "flex"}}>
                            <RegistrationLicensePlateField licensePlateField={'previousLicensePlate'}/>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </>
})

export {
    PreviousRegistrationPanelUm
}
