import {
    DeliveryAddressDto,
    DeliveryAddressDtoGenderEnum,
    DeliveryInformationDto,
    DeliveryInformationDtoDeliveryDocumentTypeEnum,
    OrganizationDto,
    RegistrationDto
} from "../api";
import {SelectOption} from "../components/ui/OrganizationSelect";

export function mapOrganizationDtoToSelectOption(o: OrganizationDto): SelectOption<OrganizationDto> {
    return {
        label: `${o.name}`,
        value: o
    }
}

export function getFor(data: RegistrationDto, type: DeliveryInformationDtoDeliveryDocumentTypeEnum): DeliveryInformationDto | undefined {
    if (data?.deliveryInformation?.length === 1) {
        return type === DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL ? data?.deliveryInformation[0] : undefined
    }
    return data?.deliveryInformation?.filter(e => e.deliveryDocumentType === type)[0]
}

export function toDeliveryAddressDto(val: DeliveryFormData): DeliveryAddressDto {
    const {
        city,
        corporate,
        firstName,
        name,
        houseNumber,
        street,
        zip,
        title,
        addition
    } = val

    return {
        addressAddition: addition || undefined,
        street: street || undefined,
        houseNumber: houseNumber || undefined,
        firstName: corporate ? undefined : (firstName || undefined),
        name: name || undefined,
        corporate: corporate || false,
        gender: corporate ? DeliveryAddressDtoGenderEnum.NOT_PROVIDED : ((title as DeliveryAddressDtoGenderEnum) || DeliveryAddressDtoGenderEnum.NOT_PROVIDED),
        postCode: zip || undefined,
        city: city || undefined
    }
}

export function toDeliveryFormData(deliveryAddress: DeliveryAddressDto): DeliveryFormData {
    const {
        city,
        corporate,
        firstName,
        name,
        houseNumber,
        street,
        gender,
        postCode,
        addressAddition
    } = deliveryAddress

    return {
        addition: addressAddition || '',
        name: name || '',
        city: city || '',
        title: gender || '',
        zip: postCode || '',
        corporate: corporate || false,
        firstName: firstName || '',
        houseNumber: houseNumber || '',
        street: street || ''
    }
}

export interface OpenIbanResult {
    valid: boolean
    iban: string
    bankData: {
        name: string
        zip: string
        city: string
        bic: string
        bankCode: string
    }
}

export interface BankData {
    iban?: string
    bic?: string
}

export interface BankDisplayData {
    valid: boolean,
    accountNumber: string,
    iban: string
    name: string
    bankCode: string
    bic: string
}

export interface DeliveryFormData {
    corporate: boolean,
    street: string,
    houseNumber: string,
    addition: string,
    city: string,
    zip: string,
    name: string,
    firstName: string,
    title: string,
}

export enum DocumentStatusEnum {
    NOT_SET = "NOT_SET",
    SUBMITTED = "SUBMITTED",
    SIGNED = "SIGNED",
    UNSIGNED = "UNSIGNED",
    SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export async function validateIban(iban: string): Promise<BankDisplayData> {
    try {
        const result = await fetch(`https://openiban.com/validate/${iban}?getBIC=true&validateBankCode=true`, {
            method: 'GET'
        })
        const r = (await result.json()) as OpenIbanResult

        if (r && r.valid) {
            return {
                valid: true,
                iban: r.iban,
                bic: r.bankData.bic,
                name: r.bankData.name,
                bankCode: r.bankData.bankCode,
                accountNumber: r.iban.split(r.bankData.bankCode)[1]
            }
        }
    } catch (e) {}

    return {
        iban: iban,
        valid: false,
        accountNumber: '',
        bic: '',
        bankCode: '',
        name: ''
    }
}

