import {observer} from "mobx-react";
import {tenantSelectStore} from "../../../store/TenantSelectStore";

import "./TenantSelectionPage.scss"
import {useState} from "react";
import {authStore} from "../../../store";

const TenantSelectionPage = observer(function TenantSelectionPage() {

    const [selected, setSelected] = useState<string>()

    return <div className={"tenant-selection-page"}>
        <div className="container is-fullhd" style={{maxWidth: "40em"}}>
            <nav className="panel is-small is-warning">
                <p className="panel-heading">Tenant Auswahl</p>
                <div className="panel-block">
                    <div style={{flex: "1"}}>
                        {tenantSelectStore.tenants.map(tenant => <>
                            <div
                                key={tenant}
                                className={`item ${selected===tenant?'selected':''}`}
                                onClick={() => {
                                    setSelected(tenant)
                                }}>{tenant}</div>
                        </>)}
                    </div>

                </div>

                <div className="panel-block">
                    <button className="button is-warning is-fullwidth switch-tenant" onClick={async () => {
                        await tenantSelectStore.switch(selected!!)
                    }} disabled={!selected}>
                        Tenant wechseln
                    </button>
                    <button className="button is-danger is-fullwidth logout" onClick={() => { authStore.logout() }}>
                        Log out
                    </button>
                </div>
            </nav>
        </div>
    </div>
})

export {
    TenantSelectionPage
}
